import "../src/assets/fonts/Proxima-Font-Family/proximanova-black.otf";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import AuthContextProvider from "./contexts/AuthContext";
import RequireAuth from "./components/RequireAuth";
import App from "./components/app";
import "./index.scss";
import {
  addBNewBillRoute,
  AlertEmailsTabRoute,
  billDetailsRoute,
  billsRoute,
  businessesTableRoute,
  contractPreparedRoute,
  contractSignedRoute,
  dashboardRoute,
  designRoute,
  devicesRoute,
  enerygyInsightsRoute,
  hotLeadAdminRoute,
  hotLeadAgentRoute,
  hotLeadDetailsAdminRoute,
  hotLeadDetailsAgentRoute,
  liveProspectSitesAgentRoute,
  liveProspectSitesRoute,
  monitoringRoute,
  NewSiteRoute,
  openSolarRoute,
  prospectDemoAgentRoute,
  prospectDemoRoute,
  publicSolarRoute,
  reportingRoute,
  savedOpportunitiesRoute,
  savedProspectsAgentRoute,
  savedProspectsRoute,
  settingsRoute,
  siteAddBillRoute,
  siteBillDetailsRoute,
  SiteSettingsTabRoute,
  sitesRoute,
  sitesTableRoute,
  mapLiveProspectSitesAgentRoute,
  searchPlaceRoute,
  placeTableRoute,
  searchPlaceAgentRoute,
  placeTableAgentRoute,
  registerRoute,
  financeRoute,
  environmentRoute,
  liveSitesListAdminRoute,
  liveSitesMapAdminRoute,
  liveSitesListAgentRoute,
  liveSitesMapAgentRoute,
  businessProposalRoute,
  businessProposalAdminRoute,
  businessProposalUserRoute,
  billsUserRoute,
  loanCalculatorRoute,
  addUserNewBillRoute,
  installersRoute,
  updateUserBillRoute,
  userBillDetailsRoute,
  installerDashboard,
  installerLogin,
  installerSignup,
  installerProfileSetup,
  installerJobs,
  installerProfile,
  installerSettings,
  adminInstallersRoute,
  adminCustomersRoute,
  adminInstallersProfileRoute,
  NetZeroRoute,
} from "./constant/app_route_names";
import Header from "./layout/header";
import Sidebar from "./layout/sidebar";
import { Navigate } from "react-router-dom";
// pages
const Monitoring = React.lazy(() => import("./pages/monitoring/monitoring"));
const Dashboard = React.lazy(() => import("./pages/dashboard/dashboard"));
const MapSites = React.lazy(() => import("./pages/all_sites/map"));
const TableSites = React.lazy(() => import("./pages/all_sites/sites_table"));
const ExcelSites = React.lazy(() => import("./pages/all_sites/new_site_excel"));
const AlertEmails = React.lazy(() => import("./pages/alerts/alertEmails"));
const Settings = React.lazy(() => import("./pages/settings/settings"));

const Reporting = React.lazy(() => import("./pages/reporting/reporting"));
const EnergyInsights = React.lazy(() =>
  import("./pages/energyInsights/energy_insight")
);
const SavedOpportunities = React.lazy(() =>
  import("./pages/energyInsights/saving_opportunites")
);
const Devices = React.lazy(() => import("./pages/settings/devices"));
const OpenSolar = React.lazy(() =>
  import("./pages/settings/opensolar/opensolar")
);
const Logins = React.lazy(() => import("./pages/auth/signin"));
const UserLogin = React.lazy(() => import("./pages/auth/signin_public_user"));
const SetPassword = React.lazy(() => import("./pages/auth/setPassword"));
const Register = React.lazy(() => import("./pages/auth/RegisterSimple"));
const Error401 = React.lazy(() => import("./pages/errors/error401"));
const Error400 = React.lazy(() => import("./pages/errors/error400"));
const Bills = React.lazy(() => import("./pages/settings/bills/all_bills"));
const AddBill = React.lazy(() => import("./pages/settings/bills/add_bill"));
const BillDetails = React.lazy(() =>
  import("./pages/settings/bills/bill_details")
);
const SolarAPI = React.lazy(() => import("./pages/solarAPI/solar_api_design"));
const GoogleSolarTab = React.lazy(() =>
  import("./pages/solarAPI/google_solar")
);
const GoogleSolarPublicTab = React.lazy(() =>
  import("./pages/solarAPI/google_solar_public")
);
const DesignScreen = React.lazy(() =>
  import("./pages/settings/design/design_screen")
);
const SiteSettings = React.lazy(() =>
  import("./pages/all_sites/siteSettings/site_settings")
);
const ContractsPrepared = React.lazy(() =>
  import("./pages/saleAgreed/contracts_prepared")
);
const ContractsSigned = React.lazy(() =>
  import("./pages/saleAgreed/contracts_signed")
);
const BusinessesTable = React.lazy(() =>
  import("./pages/Leads/business_table")
);
const ProspectDemo = React.lazy(() => import("./pages/Leads/search_leads"));
const SavedProspects = React.lazy(() => import("./pages/Leads/savedLeads"));
const LiveProspectSites = React.lazy(() => import("./pages/Leads/liveSites"));
const HotLeadTable = React.lazy(() =>
  import("./pages/Prospects/prospects_table")
);
const AdminInstallers = React.lazy(() =>
  import("./pages/Installers/InstallersDashboard")
);
const AdminCustomers = React.lazy(() =>
  import("./pages/Customers/CustomerDashboard")
);
const HotLeadDetails = React.lazy(() =>
  import("./pages/Prospects/prospect_profile")
);
const SearchPlace = React.lazy(() =>
  import("./pages/Leads/SearchPlace/searchPlace")
);
// created by sheheryar for public url
const SearchPlace_PuclicUrl = React.lazy(() =>
  import("./pages/Leads/SearchPlace/SearchPlaceForPublicUrl")
);

const SearchPlace_PuclicAuth = React.lazy(() =>
  import("./pages/Leads/SearchPlace/PublicAuth")
);

const ForgotPassword_PuclicAuth = React.lazy(() =>
  import("./pages/Leads/SearchPlace/PublicForgotPassword")
);

const PlacesTable = React.lazy(() =>
  import("./pages/Leads/SearchPlace/placeTable")
);
const FinanceTab = React.lazy(() => import("./pages/Finance/finance"));
const EnvironmentTab = React.lazy(() =>
  import("./pages/Environment/environment")
);

const LocationScreen = React.lazy(() =>
  import("./pages/solarAPI/locationScreen")
);

const LiveSitesTableScreen = React.lazy(() =>
  import("./pages/all_sites/live_sites_table")
);
const BusinessProposal = React.lazy(() =>
  import("./pages/businessProposal/business_propsal")
);
const BusinessProposalPublic = React.lazy(() =>
  import("./pages/businessProposal/business_propsal_public")
);

const UserBills = React.lazy(() => import("./pages/public/bills/user_bills"));
const AddUserBill = React.lazy(() =>
  import("./pages/public/bills/add_user_bill")
);
const UserBillDetails = React.lazy(() =>
  import("./pages/public/bills/user_bill_details")
);

const UpdateUserBill = React.lazy(() =>
  import("./pages/public/bills/update_user_bill")
);

const LoanCalculator = React.lazy(() =>
  import("./pages/public/loanCalculator/loanCalculator")
);
const InstallersTab = React.lazy(() =>
  import("./pages/public/installers/installers")
);
const NetZeroTab = React.lazy(() => import("./pages/NetZeroTab/NetZeroPage"));
const InstallerDashboard = React.lazy(() =>
  import("./pages/installerDashboard/dashboard")
);
const InstallerLogin = React.lazy(() => import("./pages/installerLogin/Login"));
const InstallerSignup = React.lazy(() =>
  import("./pages/installerSignup/Signup")
);
const InstallerProfileSetup = React.lazy(() =>
  import("./pages/installerProfileSetup/ProfileSetup")
);
const InstallerProfile = React.lazy(() =>
  import("./pages/installerProfileSetup/ProfileSetup")
);
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const AdminAccessWrapper = ({ children }) => {
  const location = useLocation();
  const allowedHosts = [
    "elevate.localhost",
    "elevatecu.localhost",
    "elevate.voltflo",
    "elevatecu.voltflo",
  ];
  const currentHost = window.location.hostname;

  // If the hostname is not in the allowed list, only allow the customer route
  if (!allowedHosts.includes(currentHost)) {
    return <Navigate to="/admin" state={{ from: location }} replace />;
  }

  return children;
};
const Root = () => {
  const currentHost = window.location.hostname;
  return (
    // <React.StrictMode>
    <BrowserRouter basename="/">
      <AuthContextProvider>
        <Provider store={store}>
          <React.Suspense fallback={loading}>
            <Routes>
              <Route path="/" element={<SearchPlace_PuclicAuth />}></Route>

              <Route path="/admin" element={<Logins />}></Route>
              <Route
                path="/set_password/:token"
                element={<SetPassword />}
              ></Route>

              {/* created by sheheryar for public url without route protection */}
              <Route
                path="/voltflo/search"
                element={<SearchPlace_PuclicUrl />}
              ></Route>
              <Route path="/voltflo/auth" element={<UserLogin />}></Route>
              <Route
                path="/voltflo/forgot"
                element={<ForgotPassword_PuclicAuth />}
              ></Route>

              <Route
                path="/voltflo/solar_proposal/:token"
                element={<BusinessProposalPublic />}
              ></Route>
              <Route
                path="/voltflo/google_solar"
                element={<GoogleSolarPublicTab />}
              ></Route>

              <Route path={registerRoute} element={<Register />}></Route>
              <Route exact path="/unauthorised" element={<Error401 />}></Route>
              <Route exact path="*" element={<Error400 />} />
              <Route
                exact
                path={publicSolarRoute}
                element={<GoogleSolarTab />}
              />

              <Route path={installerLogin} element={<InstallerLogin />} />
              <Route path={installerSignup} element={<InstallerSignup />} />
              <Route
                path={installerProfileSetup}
                element={<InstallerProfileSetup edit={false} />}
              />

              <Route element={<App />}>
                {/* Admin Routes */}
                <Route element={<RequireAuth allowedRoles={["admin"]} />}>
                  {/* Check if hostname is elevate.localhost or elevatecu.localhost */}
                  {[
                    "elevate.localhost",
                    "elevatecu.localhost",
                    "elevate.voltflo",
                    "elevatecu.voltflo",
                  ].includes(currentHost) ? (
                    // Only allow access to customer-related route
                    <Route
                      exact
                      path={adminCustomersRoute}
                      element={
                        <AdminAccessWrapper>
                          <AdminCustomers />
                        </AdminAccessWrapper>
                      }
                    />
                  ) : (
                    // Show all admin routes for localhost
                    <>
                      <Route
                        exact
                        path={dashboardRoute}
                        element={<Dashboard />}
                      />
                      <Route
                        exact
                        path={monitoringRoute}
                        element={<Monitoring />}
                      />
                      <Route exact path={sitesRoute} element={<MapSites />} />
                      <Route
                        exact
                        path={sitesTableRoute}
                        element={<TableSites />}
                      />
                      <Route
                        exact
                        path={NewSiteRoute}
                        element={<ExcelSites />}
                      />
                      <Route
                        exact
                        path={AlertEmailsTabRoute}
                        element={<AlertEmails />}
                      />
                      <Route
                        exact
                        path={SiteSettingsTabRoute}
                        element={<SiteSettings />}
                      />
                      {/* Add other routes that should be accessible for localhost */}
                      {/* <Route
                        exact
                        path={adminCustomersRoute}
                        element={
                          <AdminAccessWrapper>
                            <AdminCustomers />
                          </AdminAccessWrapper>
                        }
                      /> */}
                      <Route
                        exact
                        path={adminInstallersRoute}
                        element={<AdminInstallers />}
                      />
                      {/* Other admin routes */}
                      <Route
                        exact
                        path={liveSitesListAdminRoute}
                        element={<LiveSitesTableScreen />}
                      />
                      <Route
                        exact
                        path={liveSitesMapAdminRoute}
                        element={<MapSites />}
                      />
                      <Route
                        exact
                        path={hotLeadAdminRoute}
                        element={<HotLeadTable />}
                      />
                      <Route
                        exact
                        path={hotLeadDetailsAdminRoute}
                        element={<HotLeadDetails />}
                      />
                      <Route
                        exact
                        path={businessProposalAdminRoute}
                        element={<BusinessProposal />}
                      />
                      {/* Search Place Routes */}
                      <Route
                        exact
                        path={searchPlaceRoute}
                        element={<SearchPlace />}
                      />
                      <Route
                        exact
                        path={placeTableRoute}
                        element={<PlacesTable />}
                      />
                      <Route
                        exact
                        path={settingsRoute}
                        element={<Settings />}
                      />
                      <Route exact path={devicesRoute} element={<Devices />} />
                      <Route
                        exact
                        path={openSolarRoute}
                        element={<OpenSolar />}
                      />
                      <Route exact path={billsRoute} element={<Bills />} />
                      <Route
                        exact
                        path={addBNewBillRoute}
                        element={<AddBill />}
                      />

                      <Route
                        exact
                        path={billDetailsRoute}
                        element={<BillDetails />}
                      />

                      <Route
                        exact
                        path={designRoute}
                        element={<DesignScreen />}
                      />
                      <Route
                        exact
                        path={businessesTableRoute}
                        element={<BusinessesTable />}
                      />
                      {/* Reports */}
                      <Route
                        exact
                        path={adminInstallersProfileRoute}
                        element={<InstallerProfile edit={true} />}
                      />
                      <Route
                        exact
                        path={adminCustomersRoute}
                        element={<AdminCustomers />}
                      />
                      <Route
                        exact
                        path={reportingRoute}
                        element={<Reporting />}
                      />
                      <Route
                        exact
                        path={enerygyInsightsRoute}
                        element={<EnergyInsights />}
                      />
                      <Route
                        exact
                        path={savedOpportunitiesRoute}
                        element={<SavedOpportunities />}
                      />

                      <Route
                        exact
                        path={financeRoute}
                        element={<FinanceTab />}
                      />
                      <Route
                        exact
                        path={environmentRoute}
                        element={<EnvironmentTab />}
                      />

                      {/* Prospect */}
                      <Route
                        exact
                        path={prospectDemoRoute}
                        element={<ProspectDemo />}
                      />
                      <Route
                        exact
                        path={savedProspectsRoute}
                        element={<SavedProspects />}
                      />

                      {/* Search Place */}
                    </>
                  )}

                  {/* Other routes */}
                </Route>

                <Route element={<RequireAuth allowedRoles={["sales_agent"]} />}>
                  <Route
                    exact
                    path={prospectDemoAgentRoute}
                    element={<ProspectDemo />}
                  />
                  <Route
                    exact
                    path={savedProspectsAgentRoute}
                    element={<SavedProspects />}
                  />
                  <Route
                    exact
                    path={liveSitesListAgentRoute}
                    element={<LiveSitesTableScreen />}
                  />
                  <Route
                    exact
                    path={liveSitesMapAgentRoute}
                    element={<MapSites />}
                  />
                  <Route
                    exact
                    path={hotLeadAgentRoute}
                    element={<HotLeadTable />}
                  />
                  <Route
                    exact
                    path={hotLeadDetailsAgentRoute}
                    element={<HotLeadDetails />}
                  />
                  <Route
                    exact
                    path={mapLiveProspectSitesAgentRoute}
                    element={<SolarAPI />}
                  />

                  {/* SaleAgreed */}
                  <Route
                    exact
                    path={contractPreparedRoute}
                    element={<ContractsPrepared />}
                  />
                  <Route
                    exact
                    path={contractSignedRoute}
                    element={<ContractsSigned />}
                  />

                  {/* Search Place */}
                  <Route
                    exact
                    path={searchPlaceAgentRoute}
                    element={<SearchPlace />}
                  />
                  <Route
                    exact
                    path={placeTableAgentRoute}
                    element={<PlacesTable />}
                  />
                  <Route
                    exact
                    path={businessProposalRoute}
                    element={<BusinessProposal />}
                  />
                </Route>

                <Route element={<RequireAuth allowedRoles={["user"]} />}>
                  <Route
                    path={businessProposalUserRoute}
                    element={<BusinessProposalPublic />}
                  />

                  <Route
                    path={loanCalculatorRoute}
                    element={<LoanCalculator />}
                  />
                  <Route path={installersRoute} element={<InstallersTab />} />
                  <Route path={billsUserRoute} element={<UserBills />} />
                  <Route path={addUserNewBillRoute} element={<AddUserBill />} />
                  <Route
                    path={updateUserBillRoute}
                    element={<UpdateUserBill />}
                  />
                  <Route
                    path={userBillDetailsRoute}
                    element={<UserBillDetails />}
                  />
                  <Route path={NetZeroRoute} element={<NetZeroTab />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={["installer"]} />}>
                  <Route
                    path={installerDashboard}
                    element={<InstallerDashboard />}
                  />
                  <Route
                    path={installerJobs}
                    element={<InstallerDashboard />}
                  />
                  <Route
                    path={installerProfile}
                    element={<InstallerProfile edit={true} />}
                  />
                  <Route
                    path={installerSettings}
                    element={<InstallerDashboard />}
                  />
                </Route>
              </Route>

              {/* Catch all */}
              <Route exact path="*" element={<Error400 />} />
            </Routes>
          </React.Suspense>
        </Provider>
      </AuthContextProvider>
    </BrowserRouter>
    // </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
