import { Calculator, PinAngle, Tools } from "react-bootstrap-icons";
import {
  Home,
  Map,
  Info,
  BarChart2,
  Settings,
  Bookmark,
  Globe,
  Folder,
  DollarSign,
  Cloud,
  FileText,
  Briefcase,
  User,
} from "react-feather";
import {
  adminCustomersRoute,
  adminInstallersRoute,
  AlertEmailsTabRoute,
  billsRoute,
  billsUserRoute,
  businessProposalRoute,
  businessProposalUserRoute,
  contractPreparedRoute,
  contractSignedRoute,
  dashboardRoute,
  designRoute,
  environmentRoute,
  financeRoute,
  hotLeadAdminRoute,
  hotLeadAgentRoute,
  installerDashboard,
  installerJobs,
  installerProfile,
  installerSettings,
  installersRoute,
  liveSitesListAdminRoute,
  liveSitesListAgentRoute,
  liveSitesMapAdminRoute,
  liveSitesMapAgentRoute,
  loanCalculatorRoute,
  NetZeroRoute,
  NewSiteRoute,
  openSolarRoute,
  prospectDemoAgentRoute,
  prospectDemoRoute,
  reportingRoute,
  savedProspectsAgentRoute,
  savedProspectsRoute,
  searchPlaceAgentRoute,
  searchPlaceRoute,
  settingsRoute,
  sitesRoute,
  sitesTableRoute,
} from "src/constant/app_route_names";
import Installer from "src/assets/images/other-images/installer-img.jpg";
import NetZero from "src/assets/images/dashboard/net_zero.png";

// export const ADMIN = [
//   {
//     menutitle: "General",
//     menucontent: "",
//     Items: [
//       {
//         path: dashboardRoute,
//         icon: <Home />,
//         type: "link",
//         active: true,
//         title: "Dashboard",
//         disabled: false,
//       },

//       {
//         icon: <Map />,
//         type: "sub",
//         active: false,
//         title: "All Sites",
//         disabled: false,
//         children: [
//           {
//             path: sitesTableRoute,
//             type: "link",
//             active: false,
//             title: "List View",
//             disabled: false,
//           },
//           {
//             path: sitesRoute,
//             type: "link",
//             active: false,
//             title: "Map",
//             disabled: false,
//           },
//           {
//             path: NewSiteRoute,
//             type: "link",
//             active: false,
//             title: "Add New Site",
//             disabled: false,
//           },
//         ],
//       },
//       {
//         icon: <Info />,
//         type: "sub",
//         active: false,
//         title: "Leads",
//         disabled: false,
//         children: [
//           {
//             path: prospectDemoRoute,
//             type: "link",
//             active: false,
//             title: "Search Leads",
//             disabled: false,
//           },
//           {
//             path: searchPlaceRoute,
//             type: "link",
//             active: false,
//             title: "Search a Place",
//             disabled: false,
//           },
//           {
//             path: savedProspectsRoute,
//             type: "link",
//             active: false,
//             title: "Saved Leads",
//             disabled: false,
//           },

//           // {
//           //   path: liveProspectSitesRoute,
//           //   type: "link",
//           //   active: false,
//           //   title: "Live Sites",
//           //   disabled: false,
//           // },
//         ],
//       },

//       {
//         path: hotLeadAdminRoute,
//         icon: <Folder size={20} />,
//         type: "link",
//         active: true,
//         title: "Prospects",
//         disabled: false,
//       },
//       {
//         path: adminInstallersRoute,
//         icon: <Tools size={20} />,
//         type: "link",
//         active: true,
//         title: "Installers",
//         disabled: false,
//       },
//       {
//         path: adminCustomersRoute,
//         icon: <User size={20} />,
//         type: "link",
//         active: true,
//         title: "Customers",
//         disabled: false,
//       },
//       {
//         icon: <Globe />,
//         type: "sub",
//         active: false,
//         title: "Live Sites",
//         disabled: false,
//         children: [
//           {
//             path: liveSitesListAdminRoute,
//             type: "link",
//             active: false,
//             title: "List View",
//             disabled: false,
//           },
//           {
//             path: liveSitesMapAdminRoute,
//             type: "link",
//             active: true,
//             title: "Map View",
//             disabled: false,
//           },
//         ],
//       },

//       {
//         path: financeRoute,
//         icon: <DollarSign />,
//         type: "link",
//         active: false,
//         title: "Finance",
//         disabled: false,
//       },
//       {
//         path: environmentRoute,
//         icon: <Cloud />,
//         type: "link",
//         active: false,
//         title: "Environment",
//         disabled: false,
//       },
//       {
//         icon: <BarChart2 />,
//         type: "sub",
//         active: false,
//         title: "Reporting",
//         disabled: false,
//         children: [
//           {
//             path: reportingRoute,
//             type: "link",
//             active: false,
//             title: "Reporting",
//             disabled: false,
//           },
//           {
//             path: AlertEmailsTabRoute,
//             type: "link",
//             active: false,
//             title: "Alert Emails",
//             disabled: false,
//           },
//         ],
//       },

//       {
//         icon: <Settings />,
//         type: "sub",
//         active: false,
//         title: "Settings",
//         disabled: false,
//         children: [
//           {
//             path: settingsRoute,
//             type: "link",
//             active: false,
//             title: "System Setup",
//             disabled: false,
//           },
//           {
//             path: openSolarRoute,
//             type: "link",
//             active: false,
//             title: "Opensolar",
//             disabled: false,
//           },

//           {
//             path: billsRoute,
//             type: "link",
//             active: false,
//             title: "Bills",
//             disabled: false,
//           },
//           {
//             path: designRoute,
//             type: "link",
//             active: false,
//             title: "Solar Potential",
//             disabled: false,
//           },
//         ],
//       },
//     ],
//   },
// ];
// const getSubdomain = () => {
//   const hostname = window.location.hostname;
//   const parts = hostname.split(".");
//   return parts[0];
// };

// // Modified ADMIN menu configuration
// export const getAdminMenu = () => {
//   const subdomain = getSubdomain();
//   const isElevateSubdomain =
//     subdomain === "elevate" || subdomain === "elevatecu";

//   // If it's elevate subdomain, only show Customers menu item
//   if (isElevateSubdomain) {
//     return [
//       {
//         menutitle: "General",
//         menucontent: "",
//         Items: [
//           {
//             path: adminCustomersRoute,
//             icon: <User size={20} />,
//             type: "link",
//             active: true,
//             title: "Customers",
//             disabled: false,
//           },
//         ],
//       },
//     ];
//   }

//   // Otherwise return the full ADMIN menu
//   return [
//     {
//       menutitle: "General",
//       menucontent: "",
//       Items: [
//         {
//           path: dashboardRoute,
//           icon: <Home />,
//           type: "link",
//           active: true,
//           title: "Dashboard",
//           disabled: false,
//         },

//         {
//           icon: <Map />,
//           type: "sub",
//           active: false,
//           title: "All Sites",
//           disabled: false,
//           children: [
//             {
//               path: sitesTableRoute,
//               type: "link",
//               active: false,
//               title: "List View",
//               disabled: false,
//             },
//             {
//               path: sitesRoute,
//               type: "link",
//               active: false,
//               title: "Map",
//               disabled: false,
//             },
//             {
//               path: NewSiteRoute,
//               type: "link",
//               active: false,
//               title: "Add New Site",
//               disabled: false,
//             },
//           ],
//         },
//         {
//           icon: <Info />,
//           type: "sub",
//           active: false,
//           title: "Leads",
//           disabled: false,
//           children: [
//             {
//               path: prospectDemoRoute,
//               type: "link",
//               active: false,
//               title: "Search Leads",
//               disabled: false,
//             },
//             {
//               path: searchPlaceRoute,
//               type: "link",
//               active: false,
//               title: "Search a Place",
//               disabled: false,
//             },
//             {
//               path: savedProspectsRoute,
//               type: "link",
//               active: false,
//               title: "Saved Leads",
//               disabled: false,
//             },

//             // {
//             //   path: liveProspectSitesRoute,
//             //   type: "link",
//             //   active: false,
//             //   title: "Live Sites",
//             //   disabled: false,
//             // },
//           ],
//         },

//         {
//           path: hotLeadAdminRoute,
//           icon: <Folder size={20} />,
//           type: "link",
//           active: false,
//           title: "Prospects",
//           disabled: false,
//         },
//         {
//           path: adminInstallersRoute,
//           icon: <Tools size={20} />,
//           type: "link",
//           active: true,
//           title: "Installers",
//           disabled: false,
//         },
//         {
//           path: adminCustomersRoute,
//           icon: <User size={20} />,
//           type: "link",
//           active: true,
//           title: "Customers",
//           disabled: false,
//         },
//         {
//           icon: <Globe />,
//           type: "sub",
//           active: false,
//           title: "Live Sites",
//           disabled: false,
//           children: [
//             {
//               path: liveSitesListAdminRoute,
//               type: "link",
//               active: false,
//               title: "List View",
//               disabled: false,
//             },
//             {
//               path: liveSitesMapAdminRoute,
//               type: "link",
//               active: true,
//               title: "Map View",
//               disabled: false,
//             },
//           ],
//         },

//         {
//           path: financeRoute,
//           icon: <DollarSign />,
//           type: "link",
//           active: false,
//           title: "Finance",
//           disabled: false,
//         },
//         {
//           path: environmentRoute,
//           icon: <Cloud />,
//           type: "link",
//           active: false,
//           title: "Environment",
//           disabled: false,
//         },
//         {
//           icon: <BarChart2 />,
//           type: "sub",
//           active: false,
//           title: "Reporting",
//           disabled: false,
//           children: [
//             {
//               path: reportingRoute,
//               type: "link",
//               active: false,
//               title: "Reporting",
//               disabled: false,
//             },
//             {
//               path: AlertEmailsTabRoute,
//               type: "link",
//               active: false,
//               title: "Alert Emails",
//               disabled: false,
//             },
//           ],
//         },

//         {
//           icon: <Settings />,
//           type: "sub",
//           active: false,
//           title: "Settings",
//           disabled: false,
//           children: [
//             {
//               path: settingsRoute,
//               type: "link",
//               active: false,
//               title: "System Setup",
//               disabled: false,
//             },
//             {
//               path: openSolarRoute,
//               type: "link",
//               active: false,
//               title: "Opensolar",
//               disabled: false,
//             },

//             {
//               path: billsRoute,
//               type: "link",
//               active: false,
//               title: "Bills",
//               disabled: false,
//             },
//             {
//               path: designRoute,
//               type: "link",
//               active: false,
//               title: "Solar Potential",
//               disabled: false,
//             },
//           ],
//         },
//       ],
//     },
//   ];
// };

const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  return parts[0];
};

// Modified ADMIN menu configuration
export const getAdminMenu = () => {
  const subdomain = getSubdomain();
  const isElevateSubdomain =
    subdomain === "elevate" || subdomain === "elevatecu";
  const currentPath = window.location.pathname;

  // If it's elevate subdomain, only show Customers menu item
  if (isElevateSubdomain) {
    return [
      {
        menutitle: "General",
        menucontent: "",
        Items: [
          {
            path: adminCustomersRoute,
            icon: <User size={20} />,
            type: "link",
            active: currentPath === `/${adminCustomersRoute}`,
            title: "Customers",
            disabled: false,
          },
        ],
      },
    ];
  }

  // For the full ADMIN menu, check current path against each route
  return [
    {
      menutitle: "General",
      menucontent: "",
      Items: [
        {
          path: dashboardRoute,
          icon: <Home />,
          type: "link",
          active: currentPath === `/${dashboardRoute}`,
          title: "Dashboard",
          disabled: false,
        },
        {
          icon: <Map />,
          type: "sub",
          active: [sitesTableRoute, sitesRoute, NewSiteRoute].some(
            (route) => currentPath === `/${route}`
          ),
          title: "All Sites",
          disabled: false,
          children: [
            {
              path: sitesTableRoute,
              type: "link",
              active: currentPath === `/${sitesTableRoute}`,
              title: "List View",
              disabled: false,
            },
            {
              path: sitesRoute,
              type: "link",
              active: currentPath === `/${sitesRoute}`,
              title: "Map",
              disabled: false,
            },
            {
              path: NewSiteRoute,
              type: "link",
              active: currentPath === `/${NewSiteRoute}`,
              title: "Add New Site",
              disabled: false,
            },
          ],
        },
        {
          icon: <Info />,
          type: "sub",
          active: [
            prospectDemoRoute,
            searchPlaceRoute,
            savedProspectsRoute,
          ].some((route) => currentPath === `/${route}`),
          title: "Leads",
          disabled: false,
          children: [
            {
              path: prospectDemoRoute,
              type: "link",
              active: currentPath === `/${prospectDemoRoute}`,
              title: "Search Leads",
              disabled: false,
            },
            {
              path: searchPlaceRoute,
              type: "link",
              active: currentPath === `/${searchPlaceRoute}`,
              title: "Search a Place",
              disabled: false,
            },
            {
              path: savedProspectsRoute,
              type: "link",
              active: currentPath === `/${savedProspectsRoute}`,
              title: "Saved Leads",
              disabled: false,
            },
          ],
        },
        {
          path: hotLeadAdminRoute,
          icon: <Folder size={20} />,
          type: "link",
          active: currentPath === `/${hotLeadAdminRoute}`,
          title: "Prospects",
          disabled: false,
        },
        {
          path: adminInstallersRoute,
          icon: <Tools size={20} />,
          type: "link",
          active: currentPath === `/${adminInstallersRoute}`,
          title: "Installers",
          disabled: false,
        },
        {
          path: adminCustomersRoute,
          icon: <User size={20} />,
          type: "link",
          active: currentPath === `/${adminCustomersRoute}`,
          title: "Customers",
          disabled: false,
        },
        {
          icon: <Globe />,
          type: "sub",
          active: [liveSitesListAdminRoute, liveSitesMapAdminRoute].some(
            (route) => currentPath === `/${route}`
          ),
          title: "Live Sites",
          disabled: false,
          children: [
            {
              path: liveSitesListAdminRoute,
              type: "link",
              active: currentPath === `/${liveSitesListAdminRoute}`,
              title: "List View",
              disabled: false,
            },
            {
              path: liveSitesMapAdminRoute,
              type: "link",
              active: currentPath === `/${liveSitesMapAdminRoute}`,
              title: "Map View",
              disabled: false,
            },
          ],
        },
        {
          path: financeRoute,
          icon: <DollarSign />,
          type: "link",
          active: currentPath === `/${financeRoute}`,
          title: "Finance",
          disabled: false,
        },
        {
          path: environmentRoute,
          icon: <Cloud />,
          type: "link",
          active: currentPath === `/${environmentRoute}`,
          title: "Environment",
          disabled: false,
        },
        {
          icon: <BarChart2 />,
          type: "sub",
          active: [reportingRoute, AlertEmailsTabRoute].some(
            (route) => currentPath === `/${route}`
          ),
          title: "Reporting",
          disabled: false,
          children: [
            {
              path: reportingRoute,
              type: "link",
              active: currentPath === `/${reportingRoute}`,
              title: "Reporting",
              disabled: false,
            },
            {
              path: AlertEmailsTabRoute,
              type: "link",
              active: currentPath === `/${AlertEmailsTabRoute}`,
              title: "Alert Emails",
              disabled: false,
            },
          ],
        },
        {
          icon: <Settings />,
          type: "sub",
          active: [settingsRoute, openSolarRoute, billsRoute, designRoute].some(
            (route) => currentPath === `/${route}`
          ),
          title: "Settings",
          disabled: false,
          children: [
            {
              path: settingsRoute,
              type: "link",
              active: currentPath === `/${settingsRoute}`,
              title: "System Setup",
              disabled: false,
            },
            {
              path: openSolarRoute,
              type: "link",
              active: currentPath === `/${openSolarRoute}`,
              title: "Opensolar",
              disabled: false,
            },
            {
              path: billsRoute,
              type: "link",
              active: currentPath === `/${billsRoute}`,
              title: "Electricity Bills",
              disabled: false,
            },
            {
              path: designRoute,
              type: "link",
              active: currentPath === `/${designRoute}`,
              title: "Solar Potential",
              disabled: false,
            },
          ],
        },
      ],
    },
  ];
};

export const SALESAGENT = [
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        icon: <Info />,
        type: "sub",
        active: true,
        title: "Leads",
        disabled: false,
        children: [
          {
            icon: Map,
            // icon: <Map />,
            path: prospectDemoAgentRoute,
            type: "link",
            active: true,
            title: "Search Leads",
            disabled: false,
          },
          {
            path: searchPlaceAgentRoute,
            type: "link",
            active: false,
            title: "Search a Place",
            disabled: false,
          },
          {
            icon: Bookmark,
            // icon: <Bookmark />,
            path: savedProspectsAgentRoute,
            type: "link",
            active: false,
            title: "Saved Leads",
            disabled: false,
          },
        ],
      },

      {
        path: hotLeadAgentRoute,
        icon: <Folder size={20} />,
        type: "link",
        active: true,
        title: "Prospects",
        disabled: false,
      },
      {
        icon: <Globe />,
        type: "sub",
        active: false,
        title: "Live Sites",
        disabled: false,
        children: [
          {
            path: liveSitesListAgentRoute,
            type: "link",
            active: false,
            title: "List View",
            disabled: false,
          },
          {
            path: liveSitesMapAgentRoute,
            type: "link",
            active: true,
            title: "Map View",
            disabled: false,
          },
        ],
      },

      {
        // path: sitesRoute,
        icon: <Map />,
        type: "sub",
        active: false,
        title: "Contracts",
        disabled: false,
        // path:sitesTableRoute,
        children: [
          {
            path: contractPreparedRoute,
            type: "link",
            active: false,
            title: "Contracts Prepared",
            disabled: false,
          },
          {
            path: contractSignedRoute,
            type: "link",
            active: false,
            title: "Contracts Signed",
            disabled: false,
          },
        ],
      },

      {
        path: businessProposalRoute,
        icon: <FileText size={20} />,
        type: "link",
        active: true,
        title: "Solar Proposal",
        disabled: false,
      },
    ],
  },
];

export const USERMENU = [
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        path: businessProposalUserRoute,
        icon: <FileText size={20} />,
        type: "link",
        active: true,
        title: "Solar Proposal",
        disabled: false,
      },

      {
        path: billsUserRoute,
        icon: <Folder size={20} />,
        type: "link",
        active: false,
        title: "Electricity Bills",
        disabled: false,
      },
      {
        path: loanCalculatorRoute,
        icon: <Calculator size={20} />,
        type: "link",
        active: false,
        title: "Loan Calculator",
        disabled: false,
      },
      {
        path: installersRoute,
        icon: <Tools size={20} />,
        // icon: <img height={"20px"} src={Installer} alt="Installer" className="me-2"/>,
        type: "link",
        active: false,
        title: "Installers",
        disabled: false,
      },
      {
        path: NetZeroRoute,
        // icon: <Tools size={20} />,
        icon: <img height={"22px"} src={NetZero} alt="Installer" className="me-2"/>,
        type: "link",
        active: false,
        title: "Net Zero",
        disabled: false,
      },
    ],
  },
];

export const Manager = [
  {
    menutitle: "",
    menucontent: "",
    Items: [],
  },
];

export const INSTALLERMENU = [
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        path: installerDashboard,
        icon: <Home size={20} />,
        type: "link",
        active: true,
        title: "Dashboard",
        disabled: false,
      },

      {
        path: installerJobs,
        icon: <Briefcase size={20} />,
        type: "link",
        active: false,
        title: "Jobs",
        disabled: false,
      },
      {
        path: installerProfile,
        icon: <User size={20} />,
        type: "link",
        active: false,
        title: "Profile",
        disabled: false,
      },
      {
        path: installerSettings,
        icon: <Settings size={20} />,
        type: "link",
        active: false,
        title: "Settings",
        disabled: false,
      },
    ],
  },
];
