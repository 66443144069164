import React, { createContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import { getCarbonDailyData, productionDataRoute } from "src/api/api_routes";
import {
  adminRole,
  forecastVactual_value,
  netZero_value,
  projected_roi_value,
  return_on_investment_value,
} from "src/constant/app_constants";
import moment from "moment";
import AdminService from "src/api/admin";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [billSavings, setBillSavings] = useState("");
  const [inverterId, setInverterId] = useState("");
  const [carbonDailyData, setCarbonDailyData] = useState([]);
  const [forecastData, setForecastData] = useState([]);
  const [productionGraphData, setProductionGraphData] = useState([]);
  const[AverageAnnualKWHUsingBill,setAverageAnnualKWHUsingBill]= useState(0)
  
  const [settingsData, setSettingsData] = useState({
    location: "",
    mec: "",
    maximumExportCapacity: "",
    day_rate: 0,
    night_rate: 0,
    postcode: "",
  });

  const [systemData, setSystemData] = useState({
    company_name: "",
    total_power: 0,
    carbon_avoided: 0,
    carbon_cost: 0,
    roi: return_on_investment_value,
    forecast: forecastVactual_value,
    projected_roi: projected_roi_value,
    payback: 0,
    money_saved: 0,
    net_zero: netZero_value,
    lifetime_production: 0,
    carbon_intensity: 0,
    total_system_cost: 0,
  });

  // Shifting all the Prospect Demo states to AuthContext to persist the data (Later, we can move it to a separate file)
  const [radius, setRadius] = useState(1000);
  const [address, setAddress] = useState("Ireland");
  const [coordinates, setCoordinates] = useState(null);
  const [placeType, setPlaceType] = useState("");
  const [filterBusinessessList, setFilterBusinessessList] = useState([]);
  const [input, setInput] = useState("");
  const [map, setMap] = useState(null);
  const mapElement = useRef(null);
  const inputRef = useRef(null);
  const [marker, setMarker] = useState(null);
  const [circle, setCircle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [markerList, setMarkerList] = useState([]);
  const [disableInput, setDisableInput] = useState({
    dropdown: true,
    input: true,
  });
  const [businessessList, setBusinessessList] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showError, setShowError] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [disableFilters, setDisableFilters] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleSelection, setToggleSelection] = useState(false);
  const [showGoogleSolarData, setShowGoogleSolarData] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [combinedColumns, setCombinedColumns] = useState([]);

  const [filters, setFilters] = useState({
    name: "",
    formatted_address: "",
  });

  // ended shifting states to AuthContext

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data) {
      setAuth(data);
    }
  }, []);

  const fetchProductionData = (user_id) => {
    const userdata = {
      user_id: user_id,
    };
    AdminService.getProductionData(userdata)
      .then((response) => {
        const prod_data = response?.data?.data?.production;
        const carbon_intensity_data = response?.data?.data?.carbon_intensity;
        // set inverter id
        setInverterId(prod_data?.inverter_id);

        // Total Power
        const kilo = prod_data?.total_power ? prod_data?.total_power / 1000 : 0;
        const totalPower = kilo
          ? (Math.round(kilo * 10) / 10).toFixed(2)
          : kilo;

        // Carbon Avoided
        const carbon_avoided = prod_data?.total_power * 0.33;
        const total_carbon_avoided = carbon_avoided
          ? (Math.round(carbon_avoided * 10) / 10).toFixed(0)
          : 0;

        // console.log(prod_data?.total_power,parseInt(total_carbon_avoided), ( total_carbon_avoided * 200)/1000 )

        //  Carbon Saved Cost

        const carbonSavedCal =
          prod_data?.settings?.total_system_cost && total_carbon_avoided
            ? prod_data?.settings?.total_system_cost / total_carbon_avoided
            : 0;
        const carbonSaved = carbonSavedCal
          ? (Math.round(carbonSavedCal * 10) / 10).toFixed(2)
          : carbonSavedCal;

        setSystemData({
          ...systemData,
          total_power: totalPower * 200,
          carbon_cost: carbonSaved,
          carbon_avoided: (total_carbon_avoided * 200) / 1000,
          company_name: prod_data?.company_name,
          payback: prod_data?.payback_period,
          money_saved:
            prod_data?.total_money_saved != null
              ? (
                  Math.round(prod_data?.total_money_saved * 200 * 10) / 10
                ).toFixed(2)
              : 0,
          yearly_money_saved: prod_data?.yearly_money_saved,
          total_system_cost: prod_data?.settings?.total_system_cost,
          carbon_intensity: carbon_intensity_data,
        });

        // Set Forecast Data
        const forecast = prod_data?.daily_forecast;

        const forecast_data = Object.entries(forecast)?.map(
          ([date, value], index) => {
            const valueKWh = value ? value / 1000 : 0;
            return {
              id: index + 1,
              title: moment(date).format("ddd"),
              value: valueKWh
                ? (Math.round(valueKWh * 200 * 10) / 10).toFixed(1)
                : 0,
            };
          }
        );
        setForecastData(forecast_data);

        // Set Production Data
        const monthlyProduction = prod_data?.monthly_production;

        const productionList = Object.keys(
          monthlyProduction?.forecast || {}
        ).map((item) => {
          const formatted_date = moment(item, "YYYY-MM").format("YYYY-M");
          const formatted_date_string = moment(item, "YYYY-MM").format(
            "MMM YYYY"
          );
          const getActual = monthlyProduction?.actual.find(
            (actualItem) => actualItem?.date === formatted_date
          );
          const month = moment(item, "YYYY-MM").format("MM");

          const actualKWH = getActual ? getActual.value : 0;
          const forecastValue = monthlyProduction?.forecast[item];
          const forecastKWh = forecastValue ? forecastValue / 1000 : 0;
          return {
            date: formatted_date_string,
            actual:
              month == new Date().getMonth() + 1
                ? 0
                : actualKWH
                ? (Math.round(actualKWH * 200 * 10) / 10).toFixed(1)
                : 0,
            forecast: forecastKWh
              ? (Math.round(forecastKWh * 200 * 10) / 10).toFixed(1)
              : 0,
          };
        });

        setProductionGraphData(productionList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (auth?.accessToken && auth?.role.toString() === adminRole) {
      fetchProductionData(auth?.user_id, auth?.accessToken);
    }
  }, [auth]);

  const fetchGreenAndCarbonGraphDailyData = (inverter_id) => {
    const currentDate = moment().format("YYYY-MM-DD");
    const userdata = {
      inverterId: inverter_id,
      date: currentDate,
    };
    const url = getCarbonDailyData;
    const headers = {
      "Content-Type": "application/json",
      Authorization: auth?.accessToken,
    };
    axios
      .post(url, userdata, { headers })
      .then((response) => {
        const prod_data = response?.data?.data?.production?.data;
        const filteredData = prod_data.filter((entry) => {
          const hour = new Date(entry.date).getHours();
          return entry.value > 0 && hour >= 6 && hour <= 23;
        });
        setCarbonDailyData(filteredData);

        const production_sum = filteredData.reduce(
          (acc, entry) => acc + entry.value,
          0
        );

        // let lastItemWithCarbonIntensity = {};
        // for (let i = prod_data?.length - 1; i >= 0; i--) {
        //   if (prod_data[i].carbon_intensity > 0) {
        //     lastItemWithCarbonIntensity = prod_data[i];
        //     break;
        //   }
        // }
        // const last_carbon_intensity =
        //   lastItemWithCarbonIntensity?.carbon_intensity
        //     ? Math.round(lastItemWithCarbonIntensity?.carbon_intensity * 10) /
        //       10
        //     : 0;
        setSystemData({
          ...systemData,
          lifetime_production: production_sum,
          // carbon_intensity: last_carbon_intensity,
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (auth && inverterId) {
      fetchGreenAndCarbonGraphDailyData(inverterId);
    }
  }, [inverterId]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        billSavings,
        setBillSavings,
        systemData,
        setSystemData,
        inverterId,
        carbonDailyData,
        forecastData,
        setForecastData,
        productionGraphData,
        setProductionGraphData,
        fetchProductionData,
        settingsData,
        setSettingsData,

        radius,
        setRadius,
        coordinates,
        setCoordinates,
        placeType,
        setPlaceType,
        address,
        setAddress,
        filterBusinessessList,
        setFilterBusinessessList,
        input,
        setInput,
        map,
        setMap,
        mapElement,
        inputRef,
        marker,
        setMarker,
        circle,
        setCircle,
        loading,
        setLoading,
        markerList,
        setMarkerList,
        disableInput,
        setDisableInput,
        businessessList,
        setBusinessessList,
        showTable,
        setShowTable,
        showError,
        setShowError,
        tableLoading,
        setTableLoading,
        disableFilters,
        setDisableFilters,
        selectedRows,
        setSelectedRows,
        toggleSelection,
        setToggleSelection,
        showGoogleSolarData,
        setShowGoogleSolarData,
        dataLoading,
        setDataLoading,
        combinedColumns,
        setCombinedColumns,
        filters,
        setFilters,
        AverageAnnualKWHUsingBill,
        setAverageAnnualKWHUsingBill
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
