import React from "react";
import { Row, Col } from "reactstrap"; // Make sure to install reactstrap if not already installed
import Logo from "src/assets/images/logo/logo.png";
import Logo1 from "src/assets/images/logo/elevatecu.svg";

const LogoComponent = () => {
  // Get the current hostname and extract subdomain
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  // Check if subdomain contains 'elevate' or 'elevatecu'
  const isElevateDomain =
    subdomain.includes("elevate") || subdomain.includes("elevatecu");

  return (
    <Row style={{ alignItems: "center" }}>
      {isElevateDomain ? (
        <Col className="text-center">
          <img
            className="img-fluid for-light"
            src={Logo1}
            alt="ElevateCU Logo"
            style={{ height: 70 }}
          />
        </Col>
      ) : (
        <>
          <Col
            className="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5"
            style={{ textAlign: "right" }}
          >
            <img
              className="img-fluid for-light"
              src={Logo}
              alt="Voltflo Logo"
              style={{ height: 70 }}
            />
          </Col>
          <Col
            className="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7"
            style={{ textAlign: "left" }}
          >
            <h6
              style={{
                color: "grey",
                fontSize: 34,
                fontWeight: 400,
                marginTop: 13,
                marginLeft: -14,
              }}
            >
              Voltflo
            </h6>
          </Col>
        </>
      )}
    </Row>
  );
};

export default LogoComponent;
